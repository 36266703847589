const App = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-4xl font-bold text-red-600 text-center underline flex flex-col">
        WORK AXIS
      </h1>
      <p className="font-bold text-red-600 text-center">
        Under Construction, will be soon
      </p>
    </div>
  );
};

export default App;
